<div id="BackgroundDiv">
  <div id="OfficelinkModal">
    <div (mousedown)="onMouseDown($event)"
      class="txui-dialog-titlebar txui-widget-header txui-corner-all txui-helper-clearfix">
      <span id="txui-id-1671" class="txui-dialog-title" style=" pointer-events: none">{{'labels.expression_options' |
        translate}}</span>

      <button id="butt" (click)="onCloseClicked($event)"
        class="txui-button txui-widget txui-state-default txui-corner-all txui-button-icon-only txui-dialog-titlebar-close">
        <span class="txui-button-icon-primary txui-icon txui-icon-closethick" style="pointer-events: none"></span>
        <span class="txui-button-text" style="pointer-events: none">Close</span>
      </button>
    </div>

    <div class="txDialog txui-dialog-content txui-widget-content ">

      <div id="OfficelinkModalContent">

        <div id="mergeFieldDialog" class="txDialog txui-dialog-content txui-widget-content" style="border: 0;
        width: auto;
        min-height: 61px;
        max-height: none;
        height: auto;">

          <fieldset id="MERGE_FIELD">
            <legend id="MERGE_FIELD_FILTER">{{'labels.merge_field_filter' | translate}}</legend>
            <table style="border-collapse: collapse">
              <tbody>
                <tr>
                  <td>
                    <label id="MERGE_FIELD_CATEGORY" style="width: 100px" for="txtFieldName">{{'labels.category'|
                      translate}}</label>
                  </td>
                  <td>
                    <select size="1" id="selectedCategory" (change)="onCategoryChange($event)" style="width:500px"
                      title="selectedCategoryValue">
                      <option *ngFor="let category of categories" value="{{category}}">
                        {{category}}
                      </option>

                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label id="MERGE_FIELD_SUBCATEGORY" style="width: 100px" for="txtFieldText">{{'labels.subcategory' |
                      translate}}</label>
                  </td>
                  <td>
                    <select size="1" id="selectedSubCategory" (change)="onSubCategoryChange($event)" style="width:500px"
                      title="selectedSubCategoryValue">
                      <option *ngFor="let subCategory of subCategories" value="{{subCategory}}">
                        {{subCategory}}
                      </option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
          <fieldset id="IF_FIELD_GROUP_BOX">
            <legend id="IF_FIELD_EXP_PROPERTIES">{{'labels.expression_properties' | translate }}</legend>
            <table style="border-collapse: collapse">
              <tbody>
                <tr>
                  <td>
                    <label id="IF_FIELD_LABEL_NAME" style="width: 100px"
                      for="selMergeFieldFormat">{{'labels.merge_field_name' | translate}}</label>
                  </td>
                  <td>
                    <select size="1" id="selectedMergeField" (change)="updateSelectedMergeField()" style="width:500px"
                      title="selectedMergeFieldOption" [(ngModel)]="selectedMergeField">
                      <option *ngFor="let mergeFieldName of mergeFieldNames" value="{{mergeFieldName}}"
                        ngModel="selectedMergeField" selected="selectedMergeField">
                        {{mergeFieldName}}
                      </option>
                    </select>
                    <p *ngIf="mergeFieldError" style="color: red;">Mislukt: lege naam</p>
                  </td>
                  <td>
                    <div class="tooltipCustom"
                      style="display: none; width: auto; background-color:inherit; margin-left: 5px;"
                      id="tooltipFormatID">
                      <span id="tooltiptextID" class="tooltiptextC"></span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label id="IF_FIELD_LABEL_COMPARISON">{{'labels.comparison' | translate}}</label>
                  </td>
                  <td>
                    <select size="1" id="selectedOperator" style="width: 250px" [(ngModel)]="selectedOperator"
                      [(ngModel)]='selectedOperator'>
                      <option id="zero" value="0">{{'labels.equals' | translate}}</option>
                      <option id="one" value="1">{{'labels.not_equal_to' | translate}}</option>
                      <option id="two" value="2">{{'labels.less_than' | translate}}</option>
                      <option id="three" value="3">{{'labels.greater_than' | translate}}</option>
                      <option id="four" value="4">{{'labels.greater_than_equal' | translate}}</option>
                      <option id="five" value="5">{{'labels.less_than_equal' | translate}}</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label id="IF_FIELD_LABEL_COMPARE_TO" for="txtCompareTo">{{'labels.comparison_to' |
                      translate}}</label>
                  </td>
                  <td>
                    <input type="text" id="selectedValue" style="float: left; clear: both; width: 250px"
                      [(ngModel)]="valueToCompare" />
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
          <fieldset id="EXPRESSION_OPTIONS">
            <legend id="IF_FIELD_EXP_OPTIONS">{{'labels.expression_options' | translate}}</legend>
            <table style="border-collapse: collapse">
              <tbody>
                <tr>
                  <td>
                    <label id="IF_FIELD_LABEL_INSERT_THIS" for="txtInsertThis"
                      style="float: left; clear: both; margin-top: 5px">{{'labels.insert_this_text' |
                      translate}}</label>
                  </td>
                  <td>
                    <textarea type="text" id="trueText" style="float: left; clear: both; width: 465px"
                      [(ngModel)]="trueText"></textarea>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label id="IF_FIELD_LABEL_OTHERWISE" for="txtOtherwise"
                      style="float: left; clear: both; margin-top: 5px">{{'labels.otherwise_insert_this_text' |
                      translate}}</label>
                  </td>
                  <td>
                    <textarea type="text" id="falseText" style="float: left; clear: both; width: 465px"
                      [(ngModel)]="falseText"></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
        </div>

      </div>

      <div class="txui-dialog-buttonpane txui-helper-clearfix">
        <div class="txui-dialog-buttonset">
          <button type="button" id="modalOkButton" (click)="okCallback()"
            class="txui-button txui-widget txui-state-default txui-corner-all txui-button-text-only" role="button"
            aria-disabled="false">
            <span id="MODAL_OK" class="txui-button-text">{{'buttons.ok' | translate}}</span>
          </button>

          <button type="button" id="modalCancelButton" (click)="onCloseClicked($event)"
            class="txui-button txui-widget txui-state-default txui-corner-all txui-button-text-only" role="button"
            aria-disabled="false">
            <span id="MODAL_CANCEL" class="txui-button-text">{{'buttons.cancel' | translate}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>