import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { TemplatesApiClient } from "../api-clients/TemplatesApiClient";
import { TemplateDelm } from "../types/TemplateDelm";

@Injectable({ providedIn: "root" })
export class DeleteTemplateJob implements IJob<{ model: TemplateDelm, globalMode: boolean }, VoidResult> {

  constructor(private apiClient: TemplatesApiClient) { }

  async execute(args: { model: TemplateDelm, globalMode: boolean }): Promise<VoidResult> {
    await this.apiClient.deleteTemplate(args.model, args.globalMode);
    return new VoidResult();
  }
}
