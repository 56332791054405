export class TemplateOvm {
  id: number;
  name: string;
  description: string;
  raetName?: string;
  isActive?: boolean;
  isGlobal?: boolean;
  isDefault?: boolean;
  isSelected?: boolean;
  createdBy?: string;
}
