import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { DeleteTemplateJob } from "../jobs/DeleteTemplateJob";
import { TemplateDelm } from "../types/TemplateDelm";

@Injectable({ providedIn: 'root' })
export class DeleteTemplateCmdlet extends CmdletBase<{ model: TemplateDelm, globalMode: boolean }, VoidResult> {

  constructor(private jobService: JobService, private job: DeleteTemplateJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: { model: TemplateDelm, globalMode: boolean }): Promise<VoidResult> {
    await this.jobService.runJob<DeleteTemplateJob, { model: TemplateDelm, globalMode: boolean }, VoidResult>(this.job, arg);
    return new VoidResult();
  }
}
