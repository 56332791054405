import { AfterViewChecked, AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GetTemplateBlobCmdlet } from '../../cmdlets/GetTemplateBlobCmdlet';
import { TemplateEditorFileModel } from 'src/app/base-templates/types/helpers/TemplateEditorFileModel';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomFieldDialogComponent } from '../custom-field-dialog/custom-field-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComponentContext } from 'src/app/services/ComponentContext';
import { IfFieldDialogComponent } from '../if-field-dialog/if-field-dialog.component';
import { DataDefinitionDm } from '../../types/DataDefinitionDm';
import { UpdateTemplateContentCmdlet } from '../../cmdlets/UpdateTemplateContentCmdlet';
import { AlertService } from 'src/app/common/services/AlertService';
import { AlertTypeEnum } from 'src/app/common/types/enums/alertType.enum';
import { GetModulesCmdlet } from 'src/app/modules/cmdlets/GetModulesCmdlet';
import { MergeFunctionOvm } from 'src/app/types/dataModels/MergeFunctionOvm';
import { GetMergeFunctionCmdlet } from 'src/app/merge-functions/cmdlets/GetMergeFunctionCmdlet';
import { TemplateDdm } from 'src/app/types/dataModels/TemplateDdm';
import { EditorStateService } from 'src/app/services/EditorStateService';
import { GetTemplateFileMetaByIdCmdlet } from '../../cmdlets/GetTemplateFileMetaByIdCmdlet';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from 'src/app/common/api-clients/session.service';
import { TemplatesApiClient } from '../../api-clients/TemplatesApiClient';
import { CanComponentDeactivate } from 'src/app/common/guards/unsaved-changes.guard';
import { Breadcrumb } from 'src/app/common/components/bread-crumbs/bread-crumbs.component';
import { AppInsightsLoggerService } from 'src/app/services/logging.service';
import { Title } from '@angular/platform-browser';
import { DateFieldDialogComponent } from '../date-field-dialog/date-field-dialog.component';
import { MergeBlockDialogComponent } from '../merge-block-dialog/merge-block-dialog.component';

declare const TXTextControl: any;

@Component({
  selector: 'app-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss']
})

export class TemplateEditorComponent implements OnInit, AfterViewChecked, OnDestroy, CanComponentDeactivate {
  constructor(
    private translate: TranslateService,
    private sessionService: SessionService,
    private titleService: Title,
    private getTemplateBlobCmdlet: GetTemplateBlobCmdlet,
    private templateApi: TemplatesApiClient,
    private getModulesCmdlet: GetModulesCmdlet,
    private getMergeFunctionCmdlet: GetMergeFunctionCmdlet,
    private getTemplateFileMetaCmdlet: GetTemplateFileMetaByIdCmdlet,
    private updateTemplateContentCmdlet: UpdateTemplateContentCmdlet,
    public componentContext: ComponentContext,
    private dialog: MatDialog,
    private router: Router,
    private editorStateService: EditorStateService,
    private logger: AppInsightsLoggerService,
    private route: ActivatedRoute,
    private alertService: AlertService) {
    // Event handlers binden
    this.ribbonTabsLoadedHandler = this.onRibbonTabsLoaded.bind(this);
    this.customFieldClickHandler = this.onCustomFieldClick.bind(this);
    this.ifFieldClickHandler = this.onIfFieldClick.bind(this);
    this.editFieldClickHandler = this.onEditFieldClick.bind(this);
    this.mergeBlockClickHandler = this.onMergeBlockClick.bind(this);
    this.dateFieldClickHandler = this.onDateFieldClick.bind(this);
  }

  alertTypes = AlertTypeEnum;
  templateContentDto: TemplateEditorFileModel;
  isLoaded: boolean = false;
  loadError: boolean = false;
  showCustomDialog: boolean = false;
  mergeDataSpecification: DataDefinitionDm;
  content: any;
  moduleId: number;
  moduleName: string;
  templateId: number;
  template: TemplateDdm;
  mergeFunction: MergeFunctionOvm;
  totalInputPositionChanged: number = 0;
  myCulture: string;
  contentChanged: boolean = false;
  private document: Blob;
  saveInProgress = false;
  globalMode: boolean;
  breadcrumbs: Breadcrumb[];
  ribbonsLoaded: boolean;

  private text_template_saved: string;
  private text_template_not_saved: string;
  private text_template_restored: string;
  private text_error_retrieving_data: string;
  private text_leave_confirmation: string;
  private text_templates: string;
  private text_mergefunctions: string;
  private text_global_mergefunctions: string;
  private text_edit: string;

  private ribbonTabsLoadedHandler: () => void;
  private customFieldClickHandler: (event: Event) => void;
  private ifFieldClickHandler: (event: Event) => void;
  private editFieldClickHandler: (event: Event) => void;
  private mergeBlockClickHandler: (event: Event) => void;
  private dateFieldClickHandler: (event: Event) => void;
  private documentLoadedHandler = this.onDocumentLoaded.bind(this);
  private changedHandler = this.onChanged.bind(this);
  private baseUrl: string;


  @ViewChild('documentEditorContainer', { static: false }) documentEditorContainer!: ElementRef;

  @HostListener('document:txDocumentEditorLoaded', ['$event'])
  onTxDocumentEditorLoaded() {

    // Controleer of de event listeners al zijn toegevoegd
    if (this.editorStateService.isTxTextControlInitialized) {
      return;
    }

    // Voeg event listeners toe aan TXTextControl
    TXTextControl.addEventListener("ribbonTabsLoaded", this.ribbonTabsLoadedHandler);
    TXTextControl.addEventListener("documentLoaded", this.documentLoadedHandler);

    this.editorStateService.isTxTextControlInitialized = true;

    // Laad het document als het beschikbaar is
    if (this.document) {
      this.loadDocument(this.document);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeDocumentEditor();
  }

  async ngOnInit(): Promise<void> {

    this.isLoaded = false;

    this.myCulture = this.sessionService.session.culture;

    this.translate.get([
      'messages.template_saved',
      'errors.saving_template',
      'messages.template_restored',
      'errors.retrieving_data',
      'messages.leave_confirmation',
      'headers.templates',
      'headers.mergefunctions',
      'headers.global_mergefunctions',
      'headers.edit'
    ]).subscribe(translations => {
      this.text_template_saved = translations['messages.template_saved'];
      this.text_template_not_saved = translations['errors.saving_template'];
      this.text_template_restored = translations['messages.template_restored'];
      this.text_error_retrieving_data = translations['errors.retrieving_data'];
      this.text_leave_confirmation = translations['messages.leave_confirmation'];
      this.text_templates = translations['headers.templates'];
      this.text_mergefunctions = translations['headers.mergefunctions'];
      this.text_global_mergefunctions = translations['headers.global_mergefunctions'];
      this.text_edit = translations['headers.edit'];
    });

    this.route.data.subscribe((data) => {
      this.globalMode = !!data?.isGlobal;
    });

    this.route.paramMap.subscribe(async params => {

      this.moduleId = Number(params.get('moduleId'));
      const mergeFunctionId = Number(params.get('mergeFunctionId'));
      this.templateId = Number(params.get('templateId'));

      try {
        const modules = await this.getModulesCmdlet.execute(false);
        this.moduleName = modules.find(x => x.id == this.moduleId).name;

        this.mergeFunction = await this.getMergeFunctionCmdlet.execute({ id: mergeFunctionId, forceRefresh: false });

        this.template = await this.getTemplateFileMetaCmdlet.execute({ templateId: this.templateId, forceRefresh: false });

        this.document = await this.getTemplateBlobCmdlet.execute(this.templateId);

        this.baseUrl = this.globalMode ? '/global-modules' : '/modules';

        this.translate.get(this.globalMode ? 'pageTitles.global_template_edit' : 'pageTitles.template_edit', { templateName: this.template.raetName }).subscribe((title: string) => {
          this.titleService.setTitle(title);
        });

        this.breadcrumbs = [
          { label: this.moduleName },
          { label: this.globalMode ? this.text_global_mergefunctions : this.text_mergefunctions, url: [this.baseUrl, this.moduleId, 'details'], params: { mid: this.mergeFunction.id } },
          { label: this.mergeFunction.name },
          { label: this.text_templates, url: [this.baseUrl, this.moduleId, 'merge-functions', this.mergeFunction.id, 'details'], params: { tid: this.templateId } },
          { label: this.template.raetName, url: [this.baseUrl, this.moduleId, 'merge-functions', this.mergeFunction.id, 'templates', this.templateId, 'details'] },
          { label: this.text_edit }
        ];

        this.isLoaded = true;
      } catch (error) {
        console.error(error);
        this.logger.error('Error while loading template editor', error);
        this.isLoaded = true;
        this.loadError = true;
        this.showAlert(AlertTypeEnum.warning, this.text_error_retrieving_data);
      }

      // Laad het document als TXTextControl al beschikbaar is
      if (typeof TXTextControl !== 'undefined') {
        this.loadDocument(this.document);
      }
    });
  }

  canDeactivate(): boolean {
    return !this.contentChanged;
  }

  get deactivationMessage(): string {
    return this.text_leave_confirmation;
  }

  private onDocumentLoaded() {
    // Bind to changed event after document was loaded
    TXTextControl.addEventListener("changed", this.changedHandler);
    this.resizeDocumentEditor();
  }

  private onChanged() {
    this.contentChanged = true;
  }

  ngAfterViewChecked() {
    // Resize editor with a timeout
    this.resizeDocumentEditor();
  }

  ngOnDestroy(): void {

    // Clean up event listeners
    if (typeof TXTextControl !== 'undefined') {
      TXTextControl.removeEventListener("ribbonTabsLoaded", this.ribbonTabsLoadedHandler);
      TXTextControl.removeEventListener("documentLoaded", this.documentLoadedHandler);
      TXTextControl.removeEventListener("changed", this.changedHandler);
    }

    // Verwijder click event listeners van custom elementen
    const customField = document.getElementById('txui-id-704');
    if (customField != null) {
      customField.removeEventListener("click", this.customFieldClickHandler);
    }

    const ifField = document.getElementById('txui-id-706');
    if (ifField != null) {
      ifField.removeEventListener("click", this.ifFieldClickHandler);
    }

    const editField = document.getElementById('ribbonTabReports_btnFieldProperties');
    if (editField != null) {
      editField.removeEventListener("click", this.editFieldClickHandler);
    }

    const editMergeBlock = document.getElementById('txui-id-711');
    if (editMergeBlock != null) {
      editMergeBlock.removeEventListener("click", this.mergeBlockClickHandler);
    }

    const dateField = document.getElementById('txui-id-708');
    if (dateField != null) {
      dateField.removeEventListener("click", this.dateFieldClickHandler);
    }
  }

  showAlert(type: AlertTypeEnum, text: string) {
    this.alertService.setAlert({ type, text });
  }

  blobToBase64(blob, callback) {
    var reader = new FileReader();
    reader.onload = function () {
      callback((<string>reader.result).split(',')[1]);
    };
    reader.readAsDataURL(blob);
  }

  private loadDocument(document: Blob) {
    this.blobToBase64(document, (encoded) => {
      TXTextControl.loadDocument(TXTextControl.StreamType.InternalUnicodeFormat, encoded);
      this.contentChanged = false;
    });
  }

  okCallback() {
    //   console.log('okCallback form template-editor');
  }

  cancelCallback() {

  }

  changeCheckbox() {

  }

  onMouseDown() {

  }

  selectMergeField() {

  }

  onCompleteTemplateClicked($event) {
    // Only save if needed
    if (this.contentChanged) {
      this.onSaveTemplateClicked($event).then(() => {
        const baseUrl = this.globalMode ? '/global-modules' : '/modules';
        this.router.navigate([baseUrl, this.moduleId, 'merge-functions', this.mergeFunction.id, 'templates', this.templateId, 'details'], { queryParamsHandling: 'merge' });
        this.showAlert(AlertTypeEnum.success, this.text_template_saved);
      });
    } else {
      const baseUrl = this.globalMode ? '/global-modules' : '/modules';
      this.router.navigate([baseUrl, this.moduleId, 'merge-functions', this.mergeFunction.id, 'templates', this.templateId, 'details'], { queryParamsHandling: 'merge' });
    }
  }

  async onSaveTemplateClicked($event) {
    this.saveInProgress = true;
    const self = this;
    let templateId = this.template.id;
    let templateName = this.template.name;
    let templateDescription = this.template.description;

    this.templateApi.clearTemplateCache();

    return new Promise((resolve, reject) => {
      TXTextControl.saveDocument(TXTextControl.StreamType.InternalUnicodeFormat, async function (e) {
        if (e) {
          let templateEditorFileModel = new TemplateEditorFileModel();
          templateEditorFileModel.id = templateId;
          templateEditorFileModel.content = e.data
          templateEditorFileModel.name = templateName
          templateEditorFileModel.description = templateDescription

          try {
            await self.saveDocument(templateEditorFileModel);
            self.saveInProgress = false;
            self.contentChanged = false;
          }
          catch (error) {
            self.saveInProgress = false;
            self.showAlert(AlertTypeEnum.warning, self.text_template_not_saved);
            reject('Error saving document');
          }

          resolve(e.data);
        } else {
          self.saveInProgress = false;
          self.showAlert(AlertTypeEnum.warning, this.text_template_not_saved);
          reject('Error saving document');
        }
      });
    });

  }


  async saveDocument(templateEditorFileModel) {
    await this.updateTemplateContentCmdlet.execute(templateEditorFileModel);
    this.showAlert(AlertTypeEnum.success, this.text_template_saved);
  }

  async onClearTemplateClicked($event) {
    this.loadDocument(this.document);
    this.showAlert(AlertTypeEnum.success, this.text_template_restored);
  }

  async onCancelTemplateClicked($event) {
    const baseUrl = this.globalMode ? '/global-modules' : '/modules';
    this.router.navigate([baseUrl, this.moduleId, 'merge-functions', this.mergeFunction.id, 'templates', this.templateId, 'details'], { queryParamsHandling: 'merge' });
  }

  handlerFunction() {
    //console.log('Handler ran!');
  }

  openCustomFieldDialog() {
    this.showCustomDialog = true;
    this.componentContext.isDiaglogShown = true;
    const config: MatDialogConfig = new MatDialogConfig();
    config.data = { mergeFunctionId: this.mergeFunction.id };
    const dialogRef = this.dialog.open<CustomFieldDialogComponent, any, boolean>(CustomFieldDialogComponent, config);
    const dialogInstance = dialogRef.componentInstance;

    dialogRef.afterClosed().subscribe(async (result) => {
      this.componentContext.isDiaglogShown = false;
      var mergeField = new TXTextControl.MergeField();
      mergeField.name = dialogInstance.mergeField.name;
      console.log(dialogInstance.mergeField)
      mergeField.text = "«" + dialogInstance.mergeField.name + "»";
      TXTextControl.addMergeField(mergeField);
    });
  }

  openIfFieldDialog() {
    this.showCustomDialog = true;
    this.componentContext.isDiaglogShown = true;
    const config: MatDialogConfig = new MatDialogConfig();
    const dialogRef = this.dialog.open<IfFieldDialogComponent, any, boolean>(IfFieldDialogComponent, config);
    const dialogInstance = dialogRef.componentInstance;
  }

  openEditFieldDialog() {
    console.log('open edit field dialog');
  }

  openMergeBlockDialog() {
    this.showCustomDialog = true;
    this.componentContext.isDiaglogShown = true;
    const config: MatDialogConfig = new MatDialogConfig();
    config.data = { mergeFunctionId: this.mergeFunction.id };
    const dialogRef = this.dialog.open<MergeBlockDialogComponent, any, boolean>(MergeBlockDialogComponent, config);
    const dialogInstance = dialogRef.componentInstance;
  }

  openDateFieldDialog() {
    this.showCustomDialog = true;
    this.componentContext.isDiaglogShown = true;
    const config: MatDialogConfig = new MatDialogConfig();
    const dialogRef = this.dialog.open<DateFieldDialogComponent, any, boolean>(DateFieldDialogComponent, config);
    const dialogInstance = dialogRef.componentInstance;
  }

  setStyling() {
    let tabProofing = document.getElementById('tabProofing') as HTMLElement;
    tabProofing?.style.setProperty('display', 'none')

    let tabPermissions = document.getElementById('tabPermissions') as HTMLElement;
    tabPermissions?.style.setProperty('display', 'none')

    let tabReferences = document.getElementById('tabReferences') as HTMLElement;
    tabReferences?.style.setProperty('display', 'none')

    let tabFormFields = document.getElementById('tabFormFields') as HTMLElement;
    tabFormFields?.style.setProperty('display', 'none')

    let txTglBtnSideBars = document.getElementById('txTglBtnSideBars') as HTMLElement;
    txTglBtnSideBars?.style.setProperty('visibility', 'hidden')

    let ribbonTabInsert_drpDnBtnInsertChart = document.getElementById('ribbonTabInsert_drpDnBtnInsertChart') as HTMLElement;
    ribbonTabInsert_drpDnBtnInsertChart?.style.setProperty('display', 'none')

    let ribbonTabInsert_drpDnBtnInsertBarcode = document.getElementById('ribbonTabInsert_drpDnBtnInsertBarcode') as HTMLElement;
    ribbonTabInsert_drpDnBtnInsertBarcode?.style.setProperty('display', 'none')

    let ribbonTabInsert_btnInsertSignature = document.getElementById('ribbonTabInsert_btnInsertSignature') as HTMLElement;
    ribbonTabInsert_btnInsertSignature?.style.setProperty('display', 'none')

    let ribbonGroupDataSource = document.getElementById('ribbonGroupDataSource') as HTMLElement;
    ribbonGroupDataSource?.style.setProperty('display', 'none')

    let ribbonGroupView = document.getElementById('ribbonGroupView') as HTMLElement;
    ribbonGroupView?.style.setProperty('display', 'none')

    let ribbonGroupMailMergeFinish = document.getElementById('ribbonGroupMailMergeFinish') as HTMLElement;
    ribbonGroupMailMergeFinish?.style.setProperty('display', 'none')

    let includeText = document.getElementById('txui-id-707') as HTMLElement;
    includeText?.style.setProperty('display', 'none')

    let next = document.getElementById('txui-id-709') as HTMLElement;
    next?.style.setProperty('display', 'none')

    let nextIf = document.getElementById('txui-id-710') as HTMLElement;
    nextIf?.style.setProperty('display', 'none')

    let formulas = document.getElementById('tabFormula') as HTMLElement;
    formulas?.parentElement.style.setProperty('display', 'none');
  }

  goBack() {
    this.router.navigate([this.baseUrl, this.moduleId, 'details']);
  }

  private resizeDocumentEditor() {
    if (this.documentEditorContainer) {
      const container = this.documentEditorContainer.nativeElement.parentElement;

      // Get the position and size of the container
      const containerRect = container.getBoundingClientRect();

      // Calculate available width and height
      const availableWidth = window.innerWidth - containerRect.left;
      const availableHeight = window.innerHeight - containerRect.top;

      // Set the size of the document editor
      this.documentEditorContainer.nativeElement.style.width = `${availableWidth - 30}px`;
      this.documentEditorContainer.nativeElement.style.height = `${availableHeight - 20}px`;
    }
  }

  private onRibbonTabsLoaded() {
    this.setStyling();

    const customField = document.getElementById('txui-id-704');
    if (customField != null) {
      customField.addEventListener("click", this.customFieldClickHandler);
    }

    const ifField = document.getElementById('txui-id-706');
    if (ifField != null) {
      ifField.addEventListener("click", this.ifFieldClickHandler);
    }

    const editField = document.getElementById('ribbonTabReports_btnFieldProperties');
    if (editField != null) {
      editField.addEventListener("click", this.editFieldClickHandler);
    }

    const mergeBlockField = document.getElementById('txui-id-711');
    if (mergeBlockField != null) {
      mergeBlockField.addEventListener("click", this.mergeBlockClickHandler);
    }

    const dateField = document.getElementById('txui-id-708');
    if (dateField != null) {
      dateField.addEventListener("click", this.dateFieldClickHandler);
    }

    this.ribbonsLoaded = true;
  }

  private onCustomFieldClick(event: Event) {
    this.openCustomFieldDialog();
    event.stopPropagation();
  }

  private onIfFieldClick(event: Event) {
    this.openIfFieldDialog();
    event.stopPropagation();
  }

  private onEditFieldClick(event: Event) {
    this.openEditFieldDialog();
    event.stopPropagation();
  }

  private onMergeBlockClick(event: Event) {
    this.openMergeBlockDialog();
    event.stopPropagation();
  }

  private onDateFieldClick(event: Event) {
    this.openDateFieldDialog();
    event.stopPropagation();
  }
}
