<div class="right-content-container no-bottom-padding">  
  <div class="flex-row mb-16">
    <div>      
      <app-bread-crumbs *ngIf="isLoaded && !loadError" [breadcrumbs]="breadcrumbs"></app-bread-crumbs>
      <div *ngIf="isLoaded && loadError">
        <button class="redirectBtn" (click)="goBack()">{{'buttons.back' | translate}}</button>
      </div>      
    </div>
    <div id="editor-buttons">
      <button (click)="onCancelTemplateClicked($event)" [disabled]="saveInProgress" class="editButton">{{'buttons.cancel' | translate}}</button>
      <button (click)="onClearTemplateClicked($event)" [disabled]="saveInProgress" class="editButton">{{'buttons.undo_changes' | translate}}</button>
      <button (click)="onSaveTemplateClicked($event)" [disabled]="saveInProgress || !contentChanged" class="editButton3">{{'buttons.save' | translate}}</button>
      <button (click)="onCompleteTemplateClicked($event)" [disabled]="saveInProgress" class="editButton3">{{'buttons.finish' | translate}}</button>
    </div>  
  </div>
  <alert></alert>    
  <div class="editor-container">
    <div #documentEditorContainer class="editor-absolute-container hide-ribbonbar" [ngClass]="{'loaded': ribbonsLoaded}" >
      <tx-document-editor webSocketURL="wss://_tcx.krabbl.nl:443" [uiCulture]="myCulture" [culture]="myCulture" class="document-editor"></tx-document-editor>
    </div>
  </div>
</div>