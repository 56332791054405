export const environment = {
  production: true,
  baseAddress: '/',
  backendUrl: 'https://app-io-noffice-raet-portal-api-test.azurewebsites.net',
  backendUrlApiVersion: '1',
  textControlUrl: 'wss://_tcx.krabbl.nl:443',
  oldTmUrl: 'https://tmdocs.youforcetest.com/templatemanagement/index.aspx',
  youforceUrls: 'localhost,youforce.com,youforceacc.com,youforcetest.com',
  logging: {
    level: 'debug',      
    appInsights: {
      instrumentationKey: '64cdee45-620a-4698-ba90-54f25e4ac764'
   }
  }
};

export const authConfig = {
  authority: 'https://identity.raettest.com',  
  redirectUrl: 'signin-ping',
  wellknownEndpoint: '',
  clientId: 'youforce-templatemanagement_v2-test',  
  scope: 'openid profile offline_access', // 'openid profile email offline_access api',  
  silentRenew: true,
  useRefreshToken: true,
  autoUserInfo: false,
  secureRoutes: ['https://app-io-noffice-raet-portal-api-test.azurewebsites.net']
};
