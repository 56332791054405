import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateEditorFileModel } from 'src/app/base-templates/types/helpers/TemplateEditorFileModel';
import { ComponentService } from 'src/app/services/ComponentService';
import { FileTypeEnum } from '../../types/enums/FileTypeEnum';
import { DialogComponentBase } from '../DialogComponentBase';
import CreateTemplateCmdlet from 'src/app/templates/cmdlets/CreateTemplateCmdlet';
import { TemplateOvm } from 'src/app/types/viewModels/TemplateOvm';
import CopyTemplateCmdlet from 'src/app/templates/cmdlets/CopyTemplateCmdlet';
import { TemplateCopyIm } from 'src/app/templates/types/TemplateCopyIm';
import { MergeFunctionOvm } from 'src/app/types/dataModels/MergeFunctionOvm';
import { GetBaseTemplatesCmdlet } from 'src/app/base-templates/cmdlets/GetBaseTemplatesCmdlet';
import { VoidArg } from 'src/app/types/helpers/VoidArg';
import { BaseTemplateOvm } from 'src/app/types/viewModels/BaseTemplateOvm';
import { GetMergeFunctionTemplatesCmdlet } from 'src/app/merge-functions/cmdlets/GetMergeFunctionTemplatesCmdlet';
import { firstValueFrom, map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BusyService } from '../../services/BusyService';
import { AlertService } from 'src/app/services/AlertService';

@Component({
  selector: 'app-template-add-start',
  templateUrl: './template-add-start.component.html',
  styleUrls: ['./template-add-start.component.scss']
})
export class TemplateAddStartComponent extends DialogComponentBase<TemplateAddStartComponent, number> implements OnInit {
  formGroup: UntypedFormGroup;
  templateFile: Blob;
  selectedTemplateIdToCopy: number;  
  defaultTemplates: BaseTemplateOvm[];
  activeDefaultTemplates: BaseTemplateOvm[];
  selectedTemplateType: FileTypeEnum;
  mergeFunction: MergeFunctionOvm;
  fileExtension: string;
  mergeFunctionId: number;
  templates: TemplateOvm[];
  currentStepId: string = "start-step";
  globalMode: boolean = false;
  isBusy: boolean = false;
  alertToBeDisplayed: boolean = false;
  selectedFileName: string;
  config: any = {
    'use-default-template': ['start-step', 'select-default-template-step', 'name-description-step'],
    'copy-this-template': ['start-step', 'select-template-step', 'name-description-step'],
    'use-local-template': ['start-step', 'select-file-step', 'name-description-step'],
    'create-empty-template': ['start-step', 'name-description-step'],
  };

  constructor(private formBuilder: UntypedFormBuilder,    
    componentService: ComponentService,    
    private getMergeFunctionTemplatesCmdlet: GetMergeFunctionTemplatesCmdlet,
    private cd: ChangeDetectorRef,
    private busyService: BusyService,
    private http: HttpClient,
    private alertService: AlertService,
    dialogRef: MatDialogRef<TemplateAddStartComponent, number>,    
    private createTemplateCmdlet: CreateTemplateCmdlet,
    private copyTemplateCmdlet: CopyTemplateCmdlet,    
    @Inject(MAT_DIALOG_DATA) public data: any,
    private getBaseTemplatesCmdlet: GetBaseTemplatesCmdlet,
  ) {
    super(componentService, dialogRef);
  }

  @ViewChild('search') search: ElementRef;

  async ngOnInit() {

    this.alertService.hasMessage$.subscribe((hasMessage) => {
      this.alertToBeDisplayed = hasMessage;
    });

    this.mergeFunctionId = this.data.mergeFunctionId;
    this.globalMode = this.data.globalMode;

    super.ngOnInit();

    this.formGroup = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      description: ['', [Validators.required, Validators.maxLength(250)]],      
      selectedTemplate: ['', []],
      wizardType: ['use-default-template'],
    });

    this.templates = await this.getMergeFunctionTemplatesCmdlet.execute({mergeFunctionId: this.mergeFunctionId, global: this.globalMode});  
     
    if (this.templates.length > 0) {
      // Sort templates on name and select the first one
      this.templates = this.templates.sort((a, b) => a.name.localeCompare(b.name));    
      this.selectedTemplateIdToCopy = this.templates[0].id;
    }    

    this.defaultTemplates = await this.getBaseTemplatesCmdlet.execute(new VoidArg());
    this.activeDefaultTemplates = this.defaultTemplates.filter(defaultTemplate => defaultTemplate.isActive == true)
    this.isLoaded = true;
  }

  async onFormSubmitted() {

    let id: number;

    switch (this.wizardType) {

      case 'use-default-template': {

        try {
          this.isBusy = true;
          this.busyService.showBusy();

          let templateCopyIm = new TemplateCopyIm();
          templateCopyIm.description = this.f.description.value;
          templateCopyIm.name = this.f.name.value;
          templateCopyIm.mergeFunctionId = this.mergeFunctionId;
          templateCopyIm.templateId = this.selectedTemplateIdToCopy;

          id = await this.copyTemplateCmdlet.execute({model: templateCopyIm, globalMode: this.globalMode});
        } finally {
          this.isBusy = false;
          this.busyService.hideBusy();
        }

        break;
      }

      case 'copy-this-template': {

        try {
          this.isBusy = true;
          this.busyService.showBusy();

          let templateCopyIm = new TemplateCopyIm();
          templateCopyIm.description = this.f.description.value;
          templateCopyIm.name = this.f.name.value;
          templateCopyIm.mergeFunctionId = this.mergeFunctionId;
          templateCopyIm.templateId = this.selectedTemplateIdToCopy;

          id = await this.copyTemplateCmdlet.execute({model: templateCopyIm, globalMode: this.globalMode});
        } finally {
          this.isBusy = false;
          this.busyService.hideBusy();
        }

        break;
      }

      case 'use-local-template': {

        try {
          this.isBusy = true;
          this.busyService.showBusy();

          let templateEditorFileModel: TemplateEditorFileModel = await this.getModel();        
          templateEditorFileModel.description = this.f.description.value;
          templateEditorFileModel.name = this.f.name.value;
          templateEditorFileModel.mergeFunctionId = this.mergeFunctionId;
          templateEditorFileModel.documentType = this.fileExtension;

          id = await this.createTemplateCmdlet.execute({model: templateEditorFileModel, globalMode: this.globalMode});
        } finally {
          this.isBusy = false;
          this.busyService.hideBusy();
        }
        
        break;
      }

      case 'create-empty-template': {

        try {
          this.isBusy = true;
          this.busyService.showBusy();

          let templateEditorFileModel = new TemplateEditorFileModel();
          templateEditorFileModel.name = this.f.name.value;
          templateEditorFileModel.description = this.f.description.value;
          templateEditorFileModel.mergeFunctionId = this.mergeFunctionId;
          templateEditorFileModel.content = '';
          
          id = await this.createTemplateCmdlet.execute({model: templateEditorFileModel, globalMode: this.globalMode});    
        } finally {
          this.isBusy = false;
          this.busyService.hideBusy();
        }
        
        break;
      }
    }
    
    this.result = id;
    this.close();
    return;
  }

  onNextClicked(event) {
    if (!this.validateStep(this.currentStepId)) {
      return;
    }

    const currentStepId = this.currentStepId;
    const wizardConfig: string[] = this.config[this.wizardType];
    const currentIndex = wizardConfig.findIndex(x => x == currentStepId);
    const isLastStep = currentIndex == wizardConfig.length - 1;

    if (!isLastStep) {
      this.currentStepId = wizardConfig[currentIndex + 1];
      event.preventDefault();
    }
    else {
      this.onFormSubmitted();
    }

  }

  onPrevClicked(event) {
    const currentStepId = this.currentStepId;
    const wizardConfig: string[] = this.config[this.wizardType];
    const currentIndex = wizardConfig.findIndex(x => x == currentStepId);
    this.currentStepId = wizardConfig[currentIndex - 1];
    event.preventDefault();
  }

  onTemplateChange($event) {    
    this.selectedTemplateIdToCopy = $event.target.value;    
  }

  onDefaultTemplateChange($event) {    
    this.selectedTemplateIdToCopy = $event.target.value;    
  }

  onFileChange(event) {

    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.selectedFileName = event.target.files[0].name;
      // Fill this.f.name with the filename without extension
      this.f.name.setValue(this.selectedFileName.replace(/\.[^/.]+$/, ""));      
      let extension = event.target.files[0].name;
      this.fileExtension = extension.split('.').pop();      
      reader.readAsBinaryString(file);
      reader.onload = () => {

        this.templateFile = file;
        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

  onUploadFileClicked(event) {
    document.getElementById("select-file").click();    
    event.preventDefault();
  }

  get f() { return this.formGroup.controls; }

  get wizardType(): string {
    return this.f.wizardType.value;
  }

  private async getModel(): Promise<TemplateEditorFileModel> {

    const model = new TemplateEditorFileModel();
    model.name = this.f.name.value;
    model.description = this.f.description.value;
    model.mergeFunctionId = this.mergeFunctionId;

    if (this.templateFile) {
      model.content = await this.blobToBase64Async(this.templateFile);
    }

    return model;
  }

  private getEmptyDocContent(): Observable<string> {
    const url = 'assets/empty.docx';

    return this.http.get(url, { responseType: 'arraybuffer' }).pipe(
      map((arrayBuffer) => {
        const binary = new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), '');
        return btoa(binary); 
      })
    );
  }

  async blobToBase64Async(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onerror = (e) => reject(fileReader.error);
      fileReader.onloadend = (e) => {
        const dataUrl = fileReader.result as string;
        // remove "data:mime/type;base64," prefix from data url
        const base64 = dataUrl.substring(dataUrl.indexOf(',') + 1);
        resolve(base64);
      };
      fileReader.readAsDataURL(blob);
    });
  }

  private validateStep(stepId: string): boolean {

    if (stepId == 'start-step') {
      // clear this.f.name.value
      this.f.name.setValue('');
      this.selectedFileName = '';
      this.templateFile = null;
    }

    if (stepId == 'name-description-step') {
      return this.validateGenerelInfoStep();
    }

    if (stepId == 'select-file-step') {
      // Make sure a file is selected
      return !!this.selectedFileName;
    }

    // if (stepId == 'select-default-template-step') {
    //   return this.validateSelectTemplateStep();
    // }

    // if (stepId == 'select-template-step') {
    //   return this.validateSelectTemplateStep();
    // }

    return true;
  }

  validateSelectTemplateStep(): boolean {

    let result = true;

    if (!this.f.selectedTemplate.value) {
      this.f.selectedTemplate.setErrors({ required: true });
      result = false;
    }

    return result;
  }

  validateGenerelInfoStep(): boolean {

    if (this.formGroup.valid) {
      return true;
    }

    // There are invalid entries
    this.formGroup.markAllAsTouched();

    // if (!this.f.name.value) {
    //   this.f.name.setErrors({ required: true });
    //   this.f.name.markAsTouched();
    //   result = false;
    // }

    // if (!this.f.description.value) {
    //   this.f.description.setErrors({ required: true });
    //   this.f.description.markAsTouched();
    //   result = false;
    // }

    return false;
  }
}
