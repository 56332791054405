import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { TemplatesApiClient } from "../api-clients/TemplatesApiClient";

@Injectable({ providedIn: "root" })
export class UpdateTemplateEmptyContentJob implements IJob<number, VoidResult> {

  constructor(private templatesApiClient: TemplatesApiClient) { }

  async execute(arg: number): Promise<VoidResult> {
    await this.templatesApiClient.createEmptyTemplateVersion(arg);
    return new VoidResult();
  }
}
