import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { OverviewComponentBase } from 'src/app/common/components/OverviewComponentBase';
import { TableService, TableConfig } from 'src/app/common/services/TableService';
import { ComponentService } from 'src/app/services/ComponentService';
import { VoidArg } from 'src/app/types/helpers/VoidArg';
import { GetBaseTemplatesCmdlet } from '../../cmdlets/GetBaseTemplatesCmdlet';
import { BaseTemplateOvm } from 'src/app/types/viewModels/BaseTemplateOvm';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UploadBaseTemplateComponent } from 'src/app/common/components/upload-template/upload-template.component';
import { FileTypeEnum } from 'src/app/common/types/enums/FileTypeEnum';
import { ComponentContext } from 'src/app/services/ComponentContext';
import { BaseTemplateDeleteComponent } from '../base-template-delete/base-template-delete.component';
import { DeleteATemplateCmdlet } from '../../cmdlets/DeleteATemplateCmdlet';
import { UpdateBaseTemplateStatusCmdlet } from '../../cmdlets/UpdateBaseTemplateStatusCmdlet';
import { BaseTemplateUm4ActiveState } from '../../types/helpers/BaseTemplateUm4ActiveState';
import { AlertService } from 'src/app/common/services/AlertService';
import { AlertTypeEnum } from 'src/app/common/types/enums/alertType.enum';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { AppInsightsLoggerService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-base-templates',
  templateUrl: './base-templates.component.html',
  styleUrls: ['./base-templates.component.scss']
})
export class BaseTemplatesComponent extends OverviewComponentBase<BaseTemplateOvm> implements OnInit {
  constructor(    
    private router: Router,    
    private titleService: Title,
    private translate: TranslateService,
    componentService: ComponentService,    
    public componentContext: ComponentContext,
    private logger: AppInsightsLoggerService,
    private getBaseTemplatesCmdlet: GetBaseTemplatesCmdlet,    
    private deleteATemplateCmdlet: DeleteATemplateCmdlet,
    private updateBaseTemplateStatusCmdlet: UpdateBaseTemplateStatusCmdlet,
    tableService: TableService<BaseTemplateOvm>,
    private alertService: AlertService,
    private dialog: MatDialog) {
      
    super(tableService, componentService)
  }

  isLoaded = false;
  toggle = true;
  showMergeFunctions = false;
  helpPageVisibility = false;
  baseTemplateCount: number;
  displayedColumns: string[] = ["raetName", "user", "isActive", "cmds"];
  searchColumns: string[] = ["raetName"];
  baseTemplates: BaseTemplateOvm[];
  isBusy = false;

  private text_max_base_templates: string;
  private text_error_template_set_active: string;
  private text_error_deleting_template: string;
  private text_error_retrieving_data: string;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChildren('tableRow') tableRowsRef: QueryList<ElementRef>;

  protected get tableRows(): QueryList<ElementRef> {
    return this.tableRowsRef;
  }

  async ngOnInit() {    

    this.translate.get([           
      'errors.max_base_templates',
      'pageTitles.base_template_overview',
      'errors.template_set_active',
      'errors.deleting_template',
      'errors.retrieving_data'
    ]).subscribe(translations => {           
      this.text_max_base_templates = translations['errors.max_base_templates'];
      this.titleService.setTitle(translations['pageTitles.base_template_overview']);
      this.text_error_template_set_active = translations['errors.template_set_active'];
      this.text_error_deleting_template = translations['errors.deleting_template'];
      this.text_error_retrieving_data = translations['errors.retrieving_data'];
    });

    this.isLoading = false;

    this.sort.active = "raetName";
    this.sort.direction = "asc";
    this.sort.disableClear = true;

    this.sort.sortChange.subscribe(() => {
      this.refreshView();
    });

    super.ngOnInit();

    this.isLoading = true;
  }

  onEditTemplateClicked($event, row: any) {    
    $event.stopPropagation();
    // Force reload to make sure the editor is loaded correctly
    const currentParams = new URLSearchParams(window.location.search);
    const activeParam = currentParams.get('active');
    document.location.href = '/base-templates/' + row.id + '/editor' + (activeParam ? '?active=true' : '');    
  }

  onManageVersionsClicked(row: BaseTemplateOvm) {
    throw new Error('Method not implemented.');
  }

  onPageIndexChanged(index: number) {
    this.pageIndex = index;
    this.refreshView();
  }

  async loadRows(): Promise<BaseTemplateOvm[]> {
    this.isLoaded = false;
    try {
      const result = await this.getBaseTemplatesCmdlet.execute(new VoidArg());
      this.baseTemplateCount = result.length;    
      return result;
    } catch (error) {
      this.showAlert(AlertTypeEnum.danger, this.text_error_retrieving_data);
      console.error('Error while loading the base templates:', error);
      this.logger.error('Error while loading the base templates', error);
    } finally {
      this.isLoaded = true;
    }    
  }

  async onDeleteRowClicked(row: BaseTemplateOvm) {    
    this.componentContext.isDiaglogShown = true;
    const config: MatDialogConfig = new MatDialogConfig();
    config.panelClass = 'tm-dialog';
    const dialogRef = this.dialog.open<BaseTemplateDeleteComponent, any, boolean>(BaseTemplateDeleteComponent, config);
    const dialogInstance = dialogRef.componentInstance;
    dialogInstance.fileType = FileTypeEnum.Template;
    dialogInstance.selectedFile = row;
    dialogRef.afterClosed().subscribe(async (result) => {
      this.componentContext.isDiaglogShown = false;

      if (result) {
        try {
          await this.deleteATemplateCmdlet.execute(row.id);
          await this.reloadView();
        } catch (error) {
          this.showAlert(AlertTypeEnum.danger, this.text_error_deleting_template);
          console.error('Error while deleting the base template:', error);
          this.logger.error('Error while deleting the base template', error);
        }
      }
    });
  }

  async onAddNewRowClicked(mode: string) {    
    this.componentContext.isDiaglogShown = true;
    this.addNewTemplateViaUpload();
  }

  async addNewTemplateViaUpload() {
    if (this.baseTemplateCount > 9) {
      this.showAlert(AlertTypeEnum.danger, this.text_max_base_templates);
    }
    else {
      this.componentContext.isDiaglogShown = true;
      const config: MatDialogConfig = new MatDialogConfig();
      config.panelClass = 'tm-dialog';
      const dialogRef1 = this.dialog.open<UploadBaseTemplateComponent, any, boolean>(UploadBaseTemplateComponent, config);
      const dialogInstance2 = dialogRef1.componentInstance;
      dialogInstance2.selectedTemplateType = FileTypeEnum.Template;
      dialogInstance2.baseTemlateCount = this.baseTemplateCount;
      dialogRef1.afterClosed().subscribe(async (result) => {
        this.searchText = '';
        this.componentContext.isDiaglogShown = false;        
        await this.reloadView();        
      });
    }
    //await this.reloadView();
  }

  onEditRowClicked(row: BaseTemplateOvm) {
    throw new Error('Method not implemented.');
  }

  onViewRowClicked(row: BaseTemplateOvm) {
    throw new Error('Method not implemented.');
  }

  onSelectRowClicked(row: BaseTemplateOvm) {    
    this.dataPage.selectRow(row);    
    this.router.navigate(['/base-templates', row.id, 'details'], { queryParamsHandling: 'merge' });
  }


  get tableConfig(): TableConfig {
    let config: TableConfig = {
      filterConfig: {
        columns: this.searchColumns,
        searchText: this.searchText,
      },
      sortConfig: {
        sortColumn: this.sort.active,
        sortDirection: this.sort.direction,
      },
      pageConfig: {
        pageIndex: this.pageIndex,
      },
    };
    return config;
  }

  async setBaseTemplateActive(event: any, template) {
    if (this.isBusy) {
      return;
    }

    if (event) {
      event.stopPropagation(); // do not open the row      
    }
  
    let oldValue = template.isActive; // store old value
    let isActive = event.target.checked; // get new state from UI
  
    template.isActive = isActive; // immediate UI update
  
    
    try{
      this.isBusy = true;

      let baseTemplateUm4ActiveState = new BaseTemplateUm4ActiveState();
      baseTemplateUm4ActiveState.isActive = isActive;
      baseTemplateUm4ActiveState.templateId = template.id;
      await this.updateBaseTemplateStatusCmdlet.execute(baseTemplateUm4ActiveState);

    } catch (error) {      
      this.showAlert(AlertTypeEnum.danger, this.text_error_template_set_active);
      console.error('Error while updating the base template:', error);
      this.logger.error('Error while updating the base template', error);
      template.isActive = oldValue; // revert on error
    } finally {
      this.isBusy = false;
    }
  }

  showAlert(type: AlertTypeEnum, text: string) {
    this.alertService.setAlert({ type, text });
  }
}
