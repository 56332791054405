import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogComponentBase } from 'src/app/common/components/DialogComponentBase';
import { ComponentService } from 'src/app/services/ComponentService';
import { GetMergeDataSpecificationCmdlet } from '../../cmdlets/GetMergeDataSpecificationCmdlet';
import { DataDefinitionDm } from '../../types/DataDefinitionDm';
import { ProcessingStorage } from 'src/app/common/services/ProcessingStorage';
import { ActivatedRoute } from '@angular/router';
declare const TXTextControl: any;

@Component({
  selector: 'app-custom-field-dialog',
  templateUrl: './custom-field-dialog.component.html',
  styleUrl: './custom-field-dialog.component.scss'
})
export class CustomFieldDialogComponent extends DialogComponentBase<CustomFieldDialogComponent, number> implements OnInit {
  mergeField: any;
  name: any;
  closeAfterInsert: true;
  textFormat: any;
  textAfter: any;
  textBefore: any;
  dateTimeFormat: any;
  numericFormat: any;

  mergeDataSpecification: DataDefinitionDm;
  categories: string[] = [];
  subCategories: string[] = [];
  mergeFieldNames: string[] = [];
  selectedCategory: string;
  selectedSubCategory: string;
  selectedMergeField: string;
  mergeFunctionId: any;

  constructor(componentService: ComponentService,
    private getMergeDataSpecificationCmdlet: GetMergeDataSpecificationCmdlet,
    @Inject(MAT_DIALOG_DATA) public data: any,
    dialogRef: MatDialogRef<CustomFieldDialogComponent, number>) {
    super(componentService, dialogRef);
    this.mergeFunctionId = data.mergeFunctionId;
  }

  async ngOnInit() {
    super.ngOnInit();

    this.mergeDataSpecification = await this.getMergeDataSpecificationCmdlet.execute(this.mergeFunctionId);


    // for (let mergeField of this.mergeDataSpecification.mergeFields) {
    //   if (mergeField.subCategory === null) {
    //     this.subCategories.push(null);
    //     break;
    //   }
    // }

    for (let mergeField of this.mergeDataSpecification.mergeFields) {

      if (this.categories.indexOf(mergeField.category) === -1) {
        this.categories.push(mergeField.category);
      }

      this.selectedCategory = this.categories[0];

      if (this.subCategories.indexOf(mergeField.subCategory) === -1 && this.selectedCategory === mergeField.category) {
        this.subCategories.push(mergeField.subCategory);
      }

      this.selectedSubCategory = this.subCategories[0];

      if (mergeField.category === this.selectedCategory) {
        this.mergeFieldNames.push(mergeField.name);
      }

      // if (mergeField.category === null && mergeField.subCategory === null) {
      //   this.mergeFieldNames.push(mergeField.name);
      // }

    }

    this.filterMergeFields(this.selectedCategory, this.selectedSubCategory)

    this.isLoaded = true;
  }

  filterMergeFields(selectedCategory: string, selectedSubCategory: string) {
    this.mergeFieldNames.length = 0;

    for (let mergeField of this.mergeDataSpecification.mergeFields) {

      this.selectedCategory = selectedCategory;
      this.selectedSubCategory = selectedSubCategory;

      if (mergeField.category === this.selectedCategory && mergeField.subCategory === selectedSubCategory) {
        this.mergeFieldNames.push(mergeField.name);
      }
    }
  }

  getSubCategories(selectedCategory: string) {
    this.selectedCategory = selectedCategory;
    this.subCategories.length = 0;

    for (let mergeField of this.mergeDataSpecification.mergeFields) {
      if (mergeField.category === selectedCategory && mergeField.subCategory === null) {
        this.subCategories.push(null);
        break;
      }
    }

    for (let mergeField of this.mergeDataSpecification.mergeFields) {
      if (this.subCategories.indexOf(mergeField.subCategory) === -1 && mergeField.category === selectedCategory && mergeField.subCategory !== null) {
        this.subCategories.push(mergeField.subCategory);
      }
    }

    if (this.subCategories.length !== 0) {
      this.filterMergeFields(this.selectedCategory, this.selectedSubCategory);
    }
    this.selectedSubCategory = this.subCategories[0]
  }

  onCategoryChange($event) {
    this.selectedCategory = $event.target.value;
    this.getSubCategories(this.selectedCategory);
    this.filterMergeFields(this.selectedCategory, this.selectedSubCategory);
  }

  onSubCategoryChange($event) {
    this.selectedSubCategory = $event.target.value;
    this.filterMergeFields(this.selectedCategory, this.selectedSubCategory);
  }

  okCallback() {
    var mergeField = new TXTextControl.MergeField;
    mergeField.name = this.selectMergeField;
    mergeField.text = "«" + this.selectMergeField + "»";
    mergeField.textFormat = Number(this.textFormat);
    mergeField.textAfter = this.textAfter;
    mergeField.textBefore = this.textBefore;
    mergeField.dateTimeFormat = this.dateTimeFormat;
    mergeField.numericFormat = this.numericFormat;
    console.log(mergeField.TextFormatOptions)
    //this.result = true;

    TXTextControl.addMergeField(mergeField);
    //if (this.closeAfterInsert === true) {
    this.close();
    //}
  }

  changeCheckbox() {
    this.closeAfterInsert != this.closeAfterInsert;
  }

  onMouseDown() {

  }

  selectMergeField($event) {
    this.selectMergeField = $event.target.value;
  }
}
