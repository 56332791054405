import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { UpdateTemplateEmptyContentJob } from "../jobs/UpdateTemplateEmpyContentJob";

@Injectable({ providedIn: 'root' })
export class UpdateTemplateEmptyContentCmdlet extends CmdletBase<number, VoidResult> {

  constructor(private jobService: JobService, private job: UpdateTemplateEmptyContentJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: number): Promise<VoidResult> {
    await this.jobService.runJob<UpdateTemplateEmptyContentJob, number, VoidResult>(this.job, arg);
    return new VoidResult();
  }
}
